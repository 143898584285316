<template>
  <div>
    <transition name="notice-animation">
      <div
        v-if="cartNoticeOpen && (addedProduct || addedrepurchasedProducts)"
        class="absolute left-16 right-16 md:left-auto md:right-32 top-[70px] md:top-96 bg-white p-24 rounded-md shadow-pop-up md:min-w-360"
        @mouseenter="pauseCartNoticeTimer"
        @mouseleave="startCartNoticeTimer"
      >
        <div v-if="cartNoticeType === 'showCartNotice'" class="flex mb-12 items-center">
          <div
            class="w-48 h-48 rounded-md bg-grey200 flex justify-center items-center mr-12"
          >
            <nuxt-img
              provider="norce"
              v-if="addedProduct && addedProduct.imageUrl"
              :src="addedProduct.imageUrl"
              alt="cart-notice-product-image"
              class="h-30 w-30"
              preset="standard"
              height="60"
              width="60"
            />
          </div>
          <div>
            <div class="text-sm font-medium">
              {{ cartResources.addedToCartText }}
            </div>
            <div class="text-sm">
              {{ getProductName(addedProduct) }}
            </div>
          </div>
        </div>
        <div v-else-if="cartNoticeType === 'repurchaseOrder'">
          <div class="text-sm font-medium mb-12">
            {{ cartResources.addedToCartText }}
          </div>
          <div
            v-for="(product, index) in addedrepurchasedProducts"
            :key="index"
            class="flex mb-12 items-center"
          >
            <div
              class="w-48 h-48 rounded-md bg-grey200 flex justify-center items-center mr-12"
            >
              <nuxt-img
                provider="norce"
                v-if="product.imageUrl"
                :src="product.imageUrl"
                alt="cart-notice-product-image"
                class="h-30 w-30"
                preset="standard"
                height="60"
                width="60"
              />
            </div>
            <div class="text-sm">
              {{ product.name }}
            </div>
          </div>
        </div>
        <a
          class="btn btn--primary w-full"
          :href="cart?.checkoutUrl"
        >
          {{ cartResources.checkoutNavigationCTA }}
        </a>
        <button class="btn btn--small-round btn--secondary absolute top-16 right-16">
          <XMarkIcon class="h-16 w-16" @click="cartNoticeOpen = false;" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUnmount, computed } from 'vue';
import { useCartStore } from '~/store/cart';
import { CartType, type IProduct, type IVariant } from '~/api-types';
import XMarkIcon from '@heroicons/vue/24/outline/esm/XMarkIcon';
import { useGlobalContentStore } from '~/store/globalContent';

const cartStore = useCartStore();
const lastUpdatedCart = ref(cartStore.lastUpdatedCart as CartType || CartType.Standard);
const activeCartType = ref(lastUpdatedCart);
const globalContentStore = useGlobalContentStore();
const cartResources = ref(globalContentStore.cartResources);
const cartNoticeType = ref('');

const unsubscribe = cartStore.$onAction(
  ({ after, args, name }) => {
    after(() => {
      if (name == 'showCartNotice') {
        cartNoticeType.value = 'showCartNotice';
        const { product, selectedVariant } = args[0] as { product: IProduct, selectedVariant: IVariant };
  
        addedProduct.value = product;
        addedProductVariant.value = selectedVariant;
        openCartNotice();
      } else if (name == 'repurchaseOrder') {
        cartNoticeType.value = 'repurchaseOrder';
        const products = args[0];
        addedrepurchasedProducts.value = products;
        openCartNotice();
      }
    });
  }
);
type timer = ReturnType<typeof setTimeout>;
let cartNoticeTimer: timer;

const cartNoticeOpen = ref(false);
const addedProduct = ref<IProduct | null>(null);
const addedProductVariant = ref<IVariant | null>(null);
const addedrepurchasedProducts = ref<IProduct[] | null>(null);

const openCartNotice = () => {
  cartNoticeOpen.value = true;
  startCartNoticeTimer();
};

const startCartNoticeTimer = () => {
  cartNoticeTimer = setTimeout(() => cartNoticeOpen.value = false, 2000);
};

const pauseCartNoticeTimer = () => {
  clearTimeout(cartNoticeTimer);
};

const getProductName = () => {
  const product = addedProduct.value;
  const variant = addedProductVariant.value;

  const packString = variant && variant.packageSize > 1 ? ` ${variant.packageSize}-pack` : '';
  return product ? product.name + packString : '';
};

const cart = computed(() => {
  if (cartStore.lastUpdatedCart === CartType.Subscription) {
    return cartStore.subscriptionCart;
  } else {
    return cartStore.standardCart;
  }
});

onBeforeUnmount(() => unsubscribe());
</script>

<style>
.notice-animation-enter-active,
.notice-animation-leave-active {
  transition: all 0.3s ease-out;
}

.notice-animation-enter-from,
.notice-animation-leave-to {
  opacity: 0;
  transform: translateY(15%);
}
</style>
