<template>
  <div
    class="modalBg navHeight fixed w-full top-0 left-0 right-0 z-mobileMenu"
    :class="{
      'pointer-events-none': !uiStore.showCartMenu,
      'pointer-events-auto bg-overlay': uiStore.showCartMenu
    }"
  >
    <transition name="cartMenu">
      <div
        v-if="uiStore.showCartMenu && cart"
        class="fixed w-full top-0 left-0 navHeight"
        @click.self="closeMenu"
      >
        <div
          class="navArea navHeight w-[90vw] md:w-[35vw] bg-white absolute overflow-auto"
          @scroll.stop
          @touchmove.stop
        >
          <div class="w-[90vw] md:w-[35vw] verticalSpread pt-24 flex flex-col">
            <div class="w-full flex justify-end mb-12 px-24">
              <button class="w-22 h-22 bg-gray400" @click="closeMenu">
                <img
                  :src="'/icons/icon-close.svg?url'"
                  alt="close"
                  class="w-12"
                  height="12"
                  width="12"
                >
              </button>
            </div>
            <div class="pt-20 px-24 pb-16 md:pb-24 border-b border-border">
              <div class="text-xsmall-medium md:text-small-medium pb-12">{{ cartResources.cartHeadline }}</div>
              <div>
                <div
                  v-for="notice in cartNotices"
                  :key="notice.id"
                  class="p-12 rounded-md mb-12 flex"
                  :style="`background-color: ${notice.backgroundColor}`"
                >
                  <div>
                    <DynamicIcon
                      v-if="notice.icon"
                      :icon-name="notice.icon"
                      class="h-16 w-16 mr-6 mt-px sm:mt-1"
                      :style="`color: ${notice.iconColor}`"
                    />
                  </div>
                  <div class="wysiwyg wysiwyg--tiny-text" v-html="notice.text" />
                </div>
              </div>
              <div class="flex justify-between">
                <div 
                  class="btn btn--secondary text-xs md:text-sm flex-1 py-0 md:py-[11px] h-40 md:h-auto animate-all"
                  :class="{
                    'shadow-border-black border-black': activeCartType === CartType.Standard
                  }"
                  @click="setActiveCart(CartType.Standard)"
                >
                  {{ cartResources.standardCartSelect }}
                </div>
                <div 
                  class="btn btn--secondary text-xs md:text-sm flex-1 ml-8 sm:ml-16 py-8 md:py-12 animate-all"
                  :class="{
                    'shadow-border-black border-black': activeCartType === CartType.Subscription
                  }"
                  @click="setActiveCart(CartType.Subscription)"
                >
                  {{ cartResources.subscriptionCartSelect }}
                </div>
              </div>
            </div>
            <div v-if="cartStockConflict" class="px-24 mt-16">
              <GlobalsInformationNotice
                :notice="checkoutResources.cartStockConflictWarning"
                :icon="'information-circle-white'"
                class="mb-12 bg-error text-white flex-1 !text-xsmall sm:!text-xsmall"
              />
            </div>
            <div class="flex px-24 pt-8 md:pt-12 pb-16 md:pb-24 h-full overflow-y-scroll">
              <div v-if="cartItems.length > 0" class="flex-1">
                <CartMenuItem
                  v-for="item in cartItems"
                  :key="item.partNo"
                  :item="item"
                  :cart-type="activeCartType"
                  :cart-stock-conflict="cartStockConflict"
                  :delivery-type="'Standard'"
                />
              </div>
              <div v-else class="flex justify-center items-center flex-col flex-1 mt-12 md:mt-12 border-2 border-border border-dashed rounded-md min-h-160">
                <div class="text-xsmall-medium md:text-small-medium mb-8">
                  {{ cartResources.emptyCartText }}
                </div>
                <GlobalsLinkHelper
                  :to="mainSnusListingPage.url"
                  class="btn btn--primary mt-12 h-40 sm:h-48"
                  @click="closeMenu"
                >
                  {{ cartResources.emptyCartBtn }}
                </GlobalsLinkHelper>
              </div>
            </div>
            <div class="border-t border-border px-24 pb-20 pt-12 md:pt-20 mt-auto">
              <div>
                <div class="flex justify-between text-xsmall md:text-small-regular mb-8">
                  <div>
                    {{ cartResources.shippingSummation }}
                  </div>
                  <div>
                    {{ formatPrice(cart.shipping) }}
                  </div>
                </div>
                <div v-if="cart.totalDiscount > 0" class="flex justify-between text-xsmall md:text-small-regular mb-8">
                  <div>
                    {{ cartResources.discountSummation }}
                  </div>
                  <div>
                    {{ formatPrice(cart.totalDiscount) }}
                  </div>
                </div>
                <div class="flex justify-between text-xsmall-medium md:text-small-medium">
                  <div>
                    {{ cartResources.totalSummation }}
                  </div>
                  <div>
                    {{ formatPrice(cart.total) }}
                  </div>
                </div>
                <div
                  v-if="activeCartType === CartType.Subscription"
                  class="text-xsmall-medium sm:text-small-medium mb-8 mt-16"
                >
                  {{ checkoutResources.whichOrderInterval }}
                </div>
                <div
                  v-if="activeCartType === CartType.Subscription"
                  class="text-xsmall sm:text-small-regular mb-16"
                >
                  {{ checkoutResources.whichOrderIntervalDescription }}
                </div>
                <GenericSelector
                  v-if="activeCartType === CartType.Subscription"
                  :default-option="selectedInterval"
                  :items="intervalOptions"
                  :upwards-select="true"
                  @change="updateInterval"
                />
                <a
                  class="btn btn--primary w-full mt-12 md:mt-20"
                  :class="{
                    'disabled pointer-events-none': cartItems.length === 0 || cartStockConflict || (cartContainsProductInStoreOnly && !isLoggedIn),
                  }"
                  :href="cart.checkoutUrl"
                >
                  {{ activeCartType === CartType.Subscription ? cartResources.startSubscriptionCTA : cartResources.checkoutNavigationCTA }}
                </a>
              </div>
              <div class="flex items-center justify-center md:h-24 mt-20">
                <img
                  v-for="logo in paymentLogos"
                  :key="logo"
                  :src="logo"
                  class="h-16 mr-12 last:mr-0 relative sm:h-20"
                  alt="Sverige"
                  height="20"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { computed, ref } from 'vue';
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { CartType, SubscriptionInterval, type ICartItem } from '~/api-types';
import { storeToRefs } from 'pinia';
import CartMenuItem from '~/components/body/CartMenuItem.vue';
import DynamicIcon from '~/components/DynamicIcon.vue';
import GenericSelector from '~/components/form-elements/GenericSelector.vue';
import useFormatPrice from '~/composables/useFormatPrice';
import useDisableScroll from '~/composables/useDisableScroll';
const { enableScroll } = useDisableScroll();
const { formatPrice } = useFormatPrice();

const globalContentStore = useGlobalContentStore();
const checkoutSettings = useGlobalContentStore().checkoutSettings;
const uiStore = useUiStore();
const { hasLoadedCheckLoginStatus, isLoggedIn } = storeToRefs(uiStore);
const cartStore = useCartStore();
const lastUpdatedCart = ref(cartStore.lastUpdatedCart as CartType || CartType.Standard);
const activeCartType = ref(lastUpdatedCart);
const cartResources = ref(globalContentStore.cartResources);
const checkoutResources = ref(globalContentStore.checkoutResources);
const mainSnusListingPage = globalContentStore.pageSettings?.mainSnusListingPage;

const intervalOptions = ref([
  { name: cartResources.value.subscriptionIntervalEveryWeek, value: SubscriptionInterval.EveryWeek },
  { name: cartResources.value.subscriptionIntervalEveryOtherWeek, value: SubscriptionInterval.EveryOtherWeek },
  { name: cartResources.value.subscriptionIntervalEveryThreeWeeks, value: SubscriptionInterval.EveryThreeWeeks },
  { name: cartResources.value.subscriptionIntervalEveryFourWeeks, value: SubscriptionInterval.EveryFourWeeks },
]);

const selectedInterval = ref(intervalOptions.value[0]);

const cartNotices = computed(() => {
  if (activeCartType.value === CartType.Subscription) {
    return globalContentStore.siteSettings.cartSettings.subscriptionCartNotices;
  } else {
    return globalContentStore.siteSettings.cartSettings.standardCartNotices;
  }
});

const initCartDefaults = () => {
  selectedInterval.value = intervalOptions.value.find(interval => interval.value === cartStore.subscriptionCart?.subscriptionInterval) || intervalOptions.value[0];

  const standardItems = cartStore.standardCart?.items || [];
  const subscriptionItems = cartStore.subscriptionCart?.items || [];
  if (standardItems.length === 0 && subscriptionItems.length > 0) {
    lastUpdatedCart.value = CartType.Subscription;
  } else {
    lastUpdatedCart.value = cartStore.lastUpdatedCart as CartType || CartType.Standard;
  }
};

const closeMenu = () => {
  uiStore.setShowCartMenu(false);
  enableScroll();
};

const setActiveCart = (cartType: CartType) => {
  activeCartType.value = cartType;
};

const cart = computed(() => {
  if (activeCartType.value === CartType.Subscription) {
    return cartStore.subscriptionCart;
  } else {
    return cartStore.standardCart;
  }
});

const paymentLogos = computed(() => {
  return globalContentStore.siteSettings.cartSettings.paymentLogoUrls || [];
});

const cartItems = computed(() => {
  return cart.value?.items || [];
});

const cartContainsProductOnlineOnly = computed(() => {
  return cart.value?.items.some((item: ICartItem) => {
    return !item.availability.inStore && item.availability.online;
  });
});

const cartContainsProductInStoreOnly = computed(() => {
  return cart.value?.items.some((item: ICartItem) => {
    return item.availability.inStore && !item.availability.online;
  });
});

// If cart contains one product online and one product in store
const cartStockConflict = computed(() => {
  if (!checkoutSettings.enableClickAndCollect) {
    return false;
  }
  return cartContainsProductInStoreOnly.value && cartContainsProductOnlineOnly.value;
});

// close menu when route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    uiStore.setShowCartMenu(false);
    enableScroll();
  }
);

watch(
  () => uiStore.showCartMenu,
  () => {
    if (uiStore.showCartMenu) initCartDefaults();
  }
);

const updateInterval = (interval: { name: string; value: SubscriptionInterval }) => {
  selectedInterval.value = interval;

  cartStore.updateInterval(interval.value);
};

</script>

<!--suppress CssUnusedSymbol -->
<style scoped lang="postcss">
.modalBg {
  transition: all 0.4s ease-out;
}
.navHeight {
  height: calc(var(--vh) * 100);
}
.navWidth {
  width: 35vw;
}
.navArea {
  right: 0;
  overscroll-behavior: contain;
}

.verticalSpread {
  height: calc(var(--vh) * 100);
}

.cartMenu-enter-active {
  transition: all 0.25s ease-out;

  @screen md {
    transition: all 0.4s ease-out;
  }
}
.cartMenu-leave-active {
  transition: all 0.25s ease-out;

  @screen md {
    transition: all 0.4s ease-out;
  }
}

.cartMenu-enter-from,
.cartMenu-leave-to {
  transform: translateX(100%);
}

.linkUnderline {
  text-underline-offset: 3px;
}

</style>
