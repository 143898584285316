<template>
  <ModalWrapper
    :closeable="true"
    :headline="'Impersonate'"
    @close="$emit('close-impersonate-modal')"
  >
    <div v-if="isImpersonating">
      <div v-if="errorMessage" class="mb-6 text-xsmall-medium text-error">
        {{ errorMessage }}
      </div>
      <button class="btn btn--primary w-full" @click="onLogOut">Logga ut</button>
    </div>
    <div v-else>
      <div class="base-medium-text mb-16">
        <p>Enter the email of the person you want to impersonate below</p>
      </div>
      <div>
        <div class="flex-1">
          <TextInput
            :id="'checkoutSignInEmail'"
            :placeholder="'Email'"
            :type="'email'"
            :form-input="true"
            :error="emailError"
            @submitInput="emailInput"
          />
          <div v-if="errorMessage" class="mt-6 text-xsmall-medium text-error">
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <div class="mt-24">
        <button
          class="btn btn--primary"
          @click="onLogIn"
        >
          <img
            v-if="loading"
            :src="'/lottie-animations/spinner-svg-white.svg?url'"
            :height="20"
            :width="20"
            class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
          >
          <span
            :class="{ 'opacity-0': loading }"
          >
            Logga in
          </span>
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import TextInput from '~/components/form-elements/TextInput.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { type IICommandResultBase } from '~/api-types';
const { apiPost } = useApiFetch();
const runtimeConfig = useRuntimeConfig();
const uiStore = useUiStore();
const globalContentStore = useGlobalContentStore();
const sharedResources = globalContentStore.sharedResources;
const { isImpersonating } = storeToRefs(uiStore);
const router = useRouter();
const loading = ref(false);

const email = ref();
const emailError = ref();
const errorMessage = ref('');

const emailInput = (input: string) => {
  email.value = input;
  errorMessage.value = '';

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = sharedResources.invalidEmail;
  }
};

const onLogIn = async() => {
  loading.value = true;
  if (!email.value || emailError.value) {
    return;
  }

  const res = await apiPost<IICommandResultBase>(
    'user/impersonate',
    {
      'email': email.value,
    }
  );

  if (!res.success) {
    if (res.errorMessage) {
      errorMessage.value = res.errorMessage;
    }
  } else {
    router.go(0);
  }
  loading.value = false;

};

const onLogOut = async() => {
  try {
    const res = await $fetch(`${runtimeConfig.public.apiUrl}user/impersonate`, {
      method: 'DELETE',
      body: {
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': globalContentStore.getAcceptLanguage,
        'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
      },
    });
    if (!res.success) {
      errorMessage.value = res.errorMessage;
    } else {
      window.location.href = '/externallogin/?returnUrl=/';
    }
  } catch (e: any) {
    console.log(e);
  }
};

const emit = defineEmits<{
  (event: 'close-impersonate-modal'): void,
}>();

</script> 
